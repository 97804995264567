import React from "react";
import {Typography} from "@material-ui/core";

const Disclaimer = () => {

    return (
        <div>
            <Typography variant="h6">Disclaimer</Typography>
            <Typography variant="body2">
                Please note that this is a hobby project to build my own air quality monitor and learn about building React websites.
                The sensor readings may not accurate. The sensors are located on the deck of my garden and therefore impacted by my BBQ.
                The sensors are not calibrated, they are diy modules purchased on the internet.
            </Typography>
            <Typography variant="body2">
                Uptime is not guaranteed as I may take the site down to work on it.
            </Typography>
        </div>
    )
}

export default Disclaimer;