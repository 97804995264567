import React from 'react';
import {
    Text, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        height: "100%",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    }
});

const ReChart = (props) => {
    const classes = useStyles();
    if (props.dataSerise === null)
        return null;

    props.dataSerise.forEach(d => {
        d.created_at = Moment(d.created_at).format("D MMM H:mm");
        d.field2 = parseFloat(d.field2);
        d.field1 = parseFloat(d.field1);
    });

    return (
        <div>
            <div className={classes.root}>

                <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                        data={props.dataSerise}
                        margin={{
                            top: 5, right: 10, left: 20, bottom: 5,
                        }}
                        syncId="anyId"
                    >
                        <XAxis dataKey="created_at" domain={['auto', 'auto']}/>
                        <YAxis
                            yAxisId="left"
                            label={
                                <Text
                                    x={0}
                                    y={0}
                                    dx={50}
                                    dy={150}
                                    offset={0}
                                    angle={-90}
                                >Temperature °C</Text>} dataKey="field3" type={"number"} domain={[0, 30]}/>
                        <Tooltip/>
                        <Legend/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <Line yAxisId="left" name="Temperature" type="natural" dot={false} dataKey="field3"
                              strokeWidth="1" stroke="#ff0000"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className={classes.root}>

                <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                        data={props.dataSerise}
                        margin={{
                            top: 5, right: 10, left: 20, bottom: 5,
                        }}
                        syncId="anyId"
                    >
                        <XAxis dataKey="created_at" domain={['auto', 'auto']}/>
                        <YAxis
                            yAxisId="leftHumidity"
                            label={{value: "Humidity  %RH", angle: -90, dy: -10}}
                            dataKey="field4" type={"number"} domain={[0, 100]}/>
                        <Tooltip/>
                        <Legend/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <Line yAxisId="leftHumidity" name="Humidity" type="natural" dot={false} dataKey="field4"
                              strokeWidth="1" stroke="#AB6200"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className={classes.root}>
                <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                        data={props.dataSerise}
                        margin={{
                            top: 5, right: 10, left: 10, bottom: 5,
                        }}
                        syncId="anyId"
                    >
                        <XAxis dataKey="created_at" domain={['auto', 'auto']}/>
                        <YAxis
                            yAxisId="left"

                            label={{value: "PM2.5 ug/m3", angle: -90, dy: -10}}
                            dataKey="field1" type={"number"} domain={[0, 'dataMax']}/>
                        <YAxis
                            yAxisId="right"
                            orientation={"right"}
                            label={{value: "PM10 ug/m3", position: "right", angle: -90, dy: -10}}
                            dataKey="field2" type={"number"} domain={[0, 'dataMax']}/>
                        <Tooltip/>
                        <Legend/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <Line yAxisId="left" name="ppm25" type="natural" dot={false} dataKey="field1" strokeWidth="1"
                              stroke="#0000bb"/>
                        <Line yAxisId="right" name="ppm10" type="natural" dot={false} dataKey="field2" strokeWidth="1"
                              stroke="#000000"/>
                    </LineChart>

                </ResponsiveContainer>
            </div>
            <div className={classes.root}>

                <ResponsiveContainer width={"100%"} height={300}>
                    <LineChart
                        data={props.dataSerise}
                        margin={{
                            top: 5, right: 10, left: 20, bottom: 5,
                        }}
                        syncId="anyId"
                    >
                        <XAxis dataKey="created_at" domain={['auto', 'auto']}/>
                        <YAxis
                            yAxisId="leftPressure"
                            label={{value: "Pressure ", angle: -90, dy: -10}}
                            dataKey="field5" type={"number"} domain={[100000, 102000]}/>
                        <Tooltip/>
                        <Legend/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <Line yAxisId="leftPressure" name="Pressure" type="natural" dot={false} dataKey="field5"
                              strokeWidth="1" stroke="#AB6200"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>

        </div>

    )
}
export default ReChart;
