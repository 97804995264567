import React from "react";
import {Typography} from "@material-ui/core";

const Introduction = () => {

    return (
        <div>
            <Typography variant="body1">
                This website contains air quality information for The Ponds area in New South Wales Australia.
            </Typography>
            <Typography variant="body1">
                PM2.5 and PM10 are measures of particulate matter in the air. PM 2.5 are particles of 2.5 micrometers or less. PM 10 are particles of 10 micrometers or less.
            </Typography>
            <a href="https://www.health.nsw.gov.au/environment/air/Pages/particulate-matter.aspx">Read more about Particulate Matter</a>
        </div>
    )
}

export default Introduction;