import React from "react";
import {Typography} from "@material-ui/core";

const ReadingTimestamp = (props) => {

    var options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Australia/Sydney',
        timeZoneName: 'short'
    };

    if (props.isLoading) {
        return (
            <div>
                <Typography variant="h6">Loading Data....</Typography>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h6">
                Last Sensor Read: {new Intl.DateTimeFormat('default', options).format(new Date(props.time))}
            </Typography>
        </div>
    )

}
export default ReadingTimestamp;