import React from "react";
import ReactSpeedometer from "react-d3-speedometer"


const PM25Gauge = (props) => {

    const calculateSegmentColours = () => {
        if (props.value < 50) {
            const result = [
                "#6ad72d",
                "#aee228",];
            return {
                segments: result,
                maxValue: 50,
                segmentStops : [0,30,50]
            };
        } else if (props.value < 120) {
            const result = [
                "#6ad72d",
                "#aee228",
                "#ecdb23",
                "#f6961e"];
            return {
                segments: result,
                maxValue: 120,
                segmentStops : [0,30,60,90,120]
            };
        } else {
            const result = [
                "#6ad72d",
                "#aee228",
                "#ecdb23",
                "#f6961e",
                "#ff471a",
                "#994833"];
            return {
                segments: result,
                maxValue: 500,
                segmentStops: [0, 30, 60, 90, 120, 250, 500]
            }
        }
    }

    return (
        <div>
            <ReactSpeedometer
                width={175}
                height={120}
                maxValue={calculateSegmentColours().maxValue}
                ringWidth={30}
                value={props.value}
                segments={calculateSegmentColours().segments.length}
                segmentColors={ calculateSegmentColours().segments}
                customSegmentStops={calculateSegmentColours().segmentStops}
                currentValueText="${value} ug/m3"
            />
        </div>
    );
};

export default PM25Gauge;