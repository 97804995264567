import React from "react";
import {Grid} from "@material-ui/core";
import Introduction from "./Introduction";

const About = () => {

    return (

      <div>
          <Grid container direction="column" spacing={1}>
              <Grid item container spacing={2}>
                  <Grid item xs={false} sm={2}/>
                  <Grid item xs={false} sm={8}>
                      <Introduction/>
                  </Grid>
                  <Grid item xs={false} sm={2}/>
              </Grid>
              <Grid item container spacing={2}>
                  <Grid item xs={false} sm={2}/>
                  <Grid item xs={false} sm={8}>Table </Grid>
                  <Grid item xs={false} sm={2}/>
              </Grid>
          </Grid>
      </div>
    );

};

export default About;