import React, {useEffect, useState} from "react";
import {Grid, Typography} from '@material-ui/core'
import * as axios from "axios";
import ReadingTimestamp from "./ReadingTimestamp";
import RefreshButton from "./RefreshButton";
import Disclaimer from "./Disclaimer";
import ReChart from "./ReChart";
import PM25Gauge from "./Gauges/PM25Gauge";
import PM10Gauge from "./Gauges/PM10Gauge";
import TemperatureGague from "./Gauges/TemperatureGague";
import HumidityGauge from "./Gauges/HumidityGauge";
import Notes from "./Notes";
import Introduction from "./Introduction";

const MainPage = () => {
    const [data, setData] = useState(
        {
            field1: 0,
            field2: 0,
            field3: 0,
            field4: 0,
            field5: 0,
            created_at: null
        });

    const [dataSeries, setDataSeries] = useState(
        null
    );

    const [isLoading, setIsLoading] = useState(
        true
    );

    useEffect(() => {
        console.log("Initial load");
        fetchSeriesData();
    }, []);

    useEffect(() => {
        const interval = setInterval(()=>{
            fetchSeriesData();
        },60000);
        return () => clearInterval(interval);
    }, []);

    const removeNth = (arr, n) => {
        for (let i = n - 1; i < arr.length; i += n) {
            arr.splice(i, 5);
        }
        ;
    };

    function fetchSeriesData() {
        setIsLoading(true);
        // setData({
        //     field1: 0,
        //     field2: 0,
        //     field3: 0,
        //     field4: 0,
        //     field5: 0,
        //     created_at: new Date(0)
        // });

        console.log("Refreshing Series Data");
        axios.get('https://api.thingspeak.com/channels/1203825/feeds.json?days=2', null)
            .then((response) => {
                //console.log(response);
                removeNth(response.data.feeds, 1);
                console.log("LENGTH: " + response.data.feeds.length);
                setDataSeries(response.data.feeds)
                setData(response.data.feeds[response.data.feeds.length - 1])
                setIsLoading(false);
            }, (error) => {
                console.log(error);
            });
    }

    return (
        <div>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={2}>
                    <Grid item xs={false} sm={1}/>
                    <Grid item xs={false} sm={8}>
                        <ReadingTimestamp time={data.created_at} isLoading={isLoading}/>
                    </Grid>
                    <Grid item xs={false} sm={2}>
                        <RefreshButton doSomething={fetchSeriesData}/>
                    </Grid>
                    <Grid item xs={false} sm={1}/>
                </Grid>
                <Grid item container spacing={0}>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={6} sm={2}>
                        <Typography align={"center"}>PM 2.5</Typography>
                        <PM25Gauge value={data.field1} maxValue={50}/>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Typography align={"center"}>PM 10</Typography>
                        <PM10Gauge value={data.field2} maxValue={50}/>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Typography align={"center"}>Temperature</Typography>
                        <TemperatureGague value={data.field3}/>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Typography align={"center"}>Humidity</Typography>
                        <HumidityGauge value={data.field4}/>
                    </Grid>
                    <Grid item xs={false} sm={2}/>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={false} sm={2}/>
                    <Grid item sm={8}>
                        <Introduction/>
                    </Grid>
                    <Grid item xs={false} sm={2}/>
                </Grid>
            </Grid>

            <ReChart dataSerise={dataSeries}/>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={1}>
                    <Grid item xs={false} sm={1}/>
                    <Grid item xs={false} sm={10}><Notes/></Grid>
                    <Grid item xs={false} sm={1}/>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item xs={false} sm={1}/>
                    <Grid item xs={false} sm={10}><Disclaimer/></Grid>
                    <Grid item xs={false} sm={1}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default MainPage;
