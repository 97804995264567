import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Refresh from "@material-ui/icons/Refresh";

const RefreshButton = (props) => {

    return (
        <div>
        <Tooltip title="Data is loaded every minute" arrow>
            <Button startIcon={<Refresh />} variant="contained" color="secondary" onClick={props.doSomething}>Force Refresh</Button>
        </Tooltip>
        </div>
    )
}

export default RefreshButton;