import React from "react";
import {Route, Switch} from "react-router";
import MainPage from "./components/MainPage";
import About from "./components/About";
import Header from "./components/Header";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";

const App = () => {

    return (
        <main>
            <Header/>
            <Switch>
                <Route path='/' component={MainPage}  exact/>
                <Route path="/about" component={About} />
                <Route path="/howitworks" component={HowItWorks} />
                <Route path="/contact" component={Contact}/>
            </Switch>
        </main>
    )

}

export default App;
