import React from "react";
import {Grid, Typography} from "@material-ui/core";
import sensor1 from '../images/sensor1.jpg';
import sensor2 from '../images/sensor2.jpg';

const HowItWorks = () => {

    return (

        <div>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={2}>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={false} sm={8}>
                        <Typography variant="h6">How it works</Typography>
                        <Typography variant="body1">Sensors</Typography>
                        <Typography variant="body2">
                            Temperature, humidity and air pressure are monitored using a BNE280 sensor. PMM25 and PM10
                            are monitored using an SDS011 sensor.
                        </Typography>
                        <Typography variant="body1">Microcontroller</Typography>
                        <Typography variant="body2">
                            The sensors are read by an ESP8266 microcontroller. This microcontroller is able to read the
                            sensors and connect to wifi in order to push the data to the cloud.
                        </Typography>
                        <Typography variant="body1">Data</Typography>
                        <Typography variant="body2">
                            The data is logged using the Thingspeak data logging site.
                        </Typography>
                        <Typography variant="body1">Website</Typography>
                        <Typography variant="body2">
                            This website is built using React JS and hosted in AWS.
                        </Typography>
                    </Grid>
                    <Grid item xs={false} sm={2}/>

                </Grid>
                <Grid item container>
                    <Grid item xs={false} sm={2}/>
                    <Grid item sm={8}>
                        <img src={sensor1} alt="Sensors" height={200} width={400}/>
                        <img src={sensor2} alt="Sensors" height={200} width={400}/>
                    </Grid>
                </Grid>


            </Grid>

        </div>

    );

};

export default HowItWorks;