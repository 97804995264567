import React from "react";
import {Typography} from "@material-ui/core";

const Notes = () => {

    return (
        <div>
            <Typography variant="h6">Notes</Typography>
            <Typography variant="body2">
                Notes here
            </Typography>
        </div>
    )
}

export default Notes;