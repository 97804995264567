import React from "react";
import {Grid, Typography} from "@material-ui/core";

const Contact = () => {

    return (

        <div>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={2}>
                    <Grid item xs={false} sm={2}/>
                    <Grid item xs={false} sm={8}>
                        <Typography variant="h6">Contact</Typography>
                        <Typography variant="body1">Feel free to email me</Typography>
                            <a href="mailto:myaussieair+contact@gmail.com ">email</a>
                    </Grid>
                    <Grid item xs={false} sm={2}/>

                </Grid>

            </Grid>
        </div>


    );

};

export default Contact;