import React from "react";
import ReactSpeedometer from "react-d3-speedometer"


const HumidityGauge = (props) => {

    return (
        <div>
            <ReactSpeedometer
                width={175}
                height={120}
                maxValue={100}
                ringWidth={30}
                value={props.value}
                segmentColors={[
                    "#6ad72d",
                    "#aee228",
                    "#ecdb23",
                    "#f6961e",
                    "#ff471a"
                ]}
                currentValueText="${value} %RH"
            />
        </div>
    );
};

export default HumidityGauge;