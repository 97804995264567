import React from 'react'
import {Toolbar, AppBar, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import {Home} from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import {Link} from "react-router-dom";
import SideDrawer from "./SideDrawer";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`
    },
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    title: {
        alignContent: "center",
        alignSelf: "center"
    },
    root: {
        flexGrow: 1,
    }
}));

const navLinks = [
    { title: `home`, path: `/` },
    { title: `about`, path: `/about` },
    { title: `how it works`, path: `/howitworks` },
    { title: `contact`, path: `/contact` }
]

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" >
                <Toolbar>
                    <Container maxWidth="md" className={classes.navbarDisplayFlex}>
                    <IconButton edge="start" color="inherit" aria-label="menu" component={Link}
                                to='/'>
                        <Home fontSize="large" />

                    </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            The Ponds NSW Air Quality
                        </Typography>

                        <Hidden smDown>
                    <List component="nav" aria-labelledby="main navigation" className={classes.navDisplayFlex}>
                        {navLinks.map(({ title, path }) => (
                            <a href={path} key={title}  className={classes.linkText} >
                                <ListItem button>
                                    <ListItemText primary={title} />
                                </ListItem>
                            </a>
                        ))}
                    </List>
                        </Hidden>
                        <Hidden mdUp>
                    <SideDrawer navLinks={navLinks}/>
                        </Hidden>
                    </Container>
                </Toolbar>
            </AppBar>
        </div>
    )
};

export default Header;