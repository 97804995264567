import React from "react";
import ReactSpeedometer from "react-d3-speedometer"


const PM10Gauge = (props) => {

    const calculateSegmentColours = () => {
        if (props.value < 100) {
            const result = [
                "#6ad72d",
                "#aee228",];
            return {
                segments: result,
                maxValue: 100,
                segmentStops : [0,50,100]
            };
        } else if (props.value < 350) {
            const result = [
                "#6ad72d",
                "#aee228",
                "#ecdb23",
                "#f6961e"];
            return {
                segments: result,
                maxValue: 350,
                segmentStops : [0,50,100,250,350]
            };
        } else {
            const result = [
                "#6ad72d",
                "#aee228",
                "#ecdb23",
                "#f6961e",
                "#ff471a",
                "#994833"];
            return {
                segments: result,
                maxValue: 500,
                segmentStops: [0, 50, 100, 250, 350, 430, 500]
            }
        }
    }

    return (
        <div>
            <ReactSpeedometer
                width={175}
                height={120}
                maxValue={calculateSegmentColours().maxValue}
                ringWidth={30}
                value={props.value}
                segmentColors={calculateSegmentColours().segments}
                customSegmentStops={calculateSegmentColours().segmentStops}
                currentValueText="${value} ug/m3"
            />
        </div>
    );
};

export default PM10Gauge;